import './FiFurniture.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiFurnitureService, IColumnItem, IDivideRows, IFurnitureItem, IMergedRows, IRowItem, ISlidingDoorItem, iPoint } from './../fiFurniture/FiFurnitureService';
import { FiPrice } from '../../services/Price';
import { FiToastService } from './../../components/fiToast/FiToastService';
import { FiExpansionPanelService } from './../fiExpansionPanel/FiExpansionPanelService';
// import * as uirouter from '@uirouter/angularjs';
import * as uirouter from 'angular-ui-router';

@Component({
    controllerAs: 'vm',
    template: require('./FiFurniture.html'),
    bindings: {
        zoom: '<',
        editMode: '<',
        newItem: '<'
    },
})
export class FiFurniture {
    static $inject = [
        '$scope',
        '$rootScope',
        'fiFurnitureService',
        'fiPrice',
        '$http',
        'fiToastService',
        '$window',
        'fiExpansionPanelService',
        '$state',
        '$mdDialog',
    ];

    constructor(
        private $rootScope: angular.IRootScopeService,
        private $scope: angular.IScope,
        private fs: FiFurnitureService,
        private ps: FiPrice,
        private $http: angular.IHttpService,
        private ts: FiToastService,
        private $window: angular.IWindowService,
        private feps: FiExpansionPanelService,
        private $state: uirouter.StateService,
        private $mdDialog: angular.material.IDialogService,
    ) {
        //
    }

    private user: any;
    image_1: string;

    counter: number = 0;

    newColumnWidth: number;
    newColumnHeight: number;
    editMode: boolean;
    newItem: boolean;
    zoom: number;
    mobile: boolean;
    keyDownUnbind: () => any;

    order = [];

    $onInit() {
        this.mobile = this.$window.innerWidth <= 768;
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.fs.setView(1);


        this.order = JSON.parse(localStorage.getItem('orderInfo'));

        // console.log('order:' + this.order);
 
    }

    resize(height: number, width: number) {

        let containerW = 0;
        let containerH = 0;

        if (this.mobile) {
            containerW = 320;
            containerH = 450;
        }
        else {
            containerW = 750;
            containerH = 450;
        }
      
        this.zoom = (containerH / ((height + 80) / 100)) / 100;

        if ((width + 80) * this.zoom < containerW) {
            return this.zoom;
        }
        else {
            return (containerW / ((width + 80) / 100)) / 100;
        }
    }



    downSize(height: number, width: number) {
        const scaleSize = this.resize(height, width);
        return 1 / scaleSize;
    }

    selectColumn(column: IColumnItem) {
        this.fs.furniture.columns.forEach((c: IColumnItem) => {

            if (c != column) {
                c.active = false;
            }

        });

        // Toggle
        if (column == this.fs.furniture.activeColumn) {
            this.fs.furniture.activeColumn = null;
            column.active = false;
        }
        else {
            column.active = true;
            this.fs.furniture.activeColumn = column;
        }

        // if (this.feps.activeExpansionPanel == 4) {
            // $('.fi-dimensions-input input').focus();
        // }

    }


    showLastColumnMessage(last: boolean, first: boolean) {
        if (last && !first) {
            return 'Vidinių skyrius aukščius koreguokite pradėdami nuo viršutinio skyriaus. Apatinio skyriaus aukštis pasiskaičiuos automatiškai.';
        }
        else {
            return '';
        }
    }

    showLastColumnInfoMessage(last: boolean, first: boolean) {
        if (last && !first && this.mobile) {
            alert('Vidinių skyrius aukščius koreguokite pradėdami nuo viršutinio skyriaus. Apatinio skyriaus aukštis pasiskaičiuos automatiškai.');
        }
    }

    selectDivideRow(door: ISlidingDoorItem, row: IDivideRows) {
        if (door == this.fs.furniture.activeDoor && row == this.fs.furniture.activeDoorRow) {
            this.fs.furniture.activeDoor = null;
            this.fs.furniture.activeDoorRow = null;
            door.active = false;
            row.active = false;
        }
        else {
            door.active = true;
            row.active = true;
            this.fs.furniture.activeDoor = door;
            this.fs.furniture.activeDoorRow = row;
        }

        this.fs.furniture.slidingDoors.forEach((c: ISlidingDoorItem) => {

            if (c != door) {
                c.active = false;
            }

            c.divideRows.forEach((r: IDivideRows) => {
                if (r != row) {
                    r.active = false;
                }
            });
        });
    }

    canSelect(row: IRowItem) {
        // if (this.feps.activeExpansionPanel == 10 && !this.fs.sameDoorsPanel) {
            // return true;
        // }
        if (this.feps.activeExpansionPanel == 11 && !this.fs.sameHandles) {
            return true;
        }
        else if (this.feps.activeExpansionPanel == 11 && this.fs.sameHandles) {
            return false;
        }
        else if (this.feps.activeExpansionPanel == 8 || this.feps.activeExpansionPanel == 9) {
            return true
        }
        // else if ((this.feps.activeExpansionPanel == 8 || 
            //this.feps.activeExpansionPanel == 9) && 
            //!row.merged && row.parent_row_id == 0 || (this.fs.activeView == 3 && this.feps.activeExpansionPanel == 8)) {
                //return true
        //}
        else {
            return false;
        }
    }

    canChangeRowHeight(column: IColumnItem, row: IRowItem) {
        // if (column.rowsCount == 1 && this.fs.furniture.type == 2 && this.fs.furniture.installation != 1 && this.fs.furniture.rails == 1) {
        if (column.rowsCount == 1 && this.fs.furniture.type == 2) {
            return false
        }
        else {
            return true
        }
    }


    showColumns() {

        if (this.fs.furniture.type == 2 && (this.feps.activeExpansionPanel == 9 || this.feps.activeExpansionPanel == 10 || this.feps.activeExpansionPanel == 11 || this.feps.activeExpansionPanel == 12 || this.feps.activeExpansionPanel == 13 || this.feps.activeExpansionPanel == 15)) {
            return false;
        }
        else {
            return true;
        }
      
    }

    selectDoor(door: ISlidingDoorItem) {

        this.fs.furniture.slidingDoors.forEach((c: ISlidingDoorItem) => {

            if (c != door) {
                c.active = false;
            }

        });

        // Toggle
        if (door == this.fs.furniture.activeDoor) {
            this.fs.furniture.activeDoor = null;
            door.active = false;
        }
        else {
            door.active = true;
            this.fs.furniture.activeDoor = door;
        }


        if (this.feps.activeExpansionPanel == 9) {
            this.$mdDialog.show({
                template: '<fi-choose-divide></fi-choose-divide>',
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                controllerAs: 'vm'
            });
        }
    }

    unactivateAllRows(column: IColumnItem, row: IRowItem) {
        this.fs.furniture.columns.forEach((c: IColumnItem) => {
    
            if (c != column) {
                c.active = false;
            }

            c.rows.forEach((r: IRowItem) => {
                if (r != row) {
                    r.active = false;
                }
            });
        });
    }

    selectRow(column: IColumnItem, row: IRowItem) {

        if (this.feps.activeExpansionPanel == 8 || this.feps.activeExpansionPanel == 9) {
            this.unactivateAllRows(column, row);
            column.active = true;
            row.active = true;
            this.fs.furniture.activeColumn = column;
            this.fs.furniture.activeRow = row;

            if (this.feps.activeExpansionPanel == 8) {
                this.$mdDialog.show({
                    template: '<fi-choose-internal></fi-choose-internal>',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    controllerAs: 'vm'
                });
            }
            else if (this.feps.activeExpansionPanel == 9) {
                this.$mdDialog.show({
                    template: '<fi-choose-outer></fi-choose-outer>',
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    controllerAs: 'vm'
                });
            }

        }
        else {
            if (column == this.fs.furniture.activeColumn && row == this.fs.furniture.activeRow) {
                this.fs.furniture.activeColumn = null;
                this.fs.furniture.activeRow = null;
                column.active = false;
                row.active = false;
            }
            else {
                column.active = true;
                row.active = true;
                this.fs.furniture.activeColumn = column;
                this.fs.furniture.activeRow = row;
            }
    
            this.unactivateAllRows(column, row);
        }
    }

    viewImage(img) {
        this.$window.open(img, '_blank');
    }

    editPoint(point: iPoint) {
        
        this.fs.point = point;
        this.$mdDialog.show({
            template: '<fi-item-point-dialog></fi-item-point-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });

    }

    showDialog(item, category) {
        if (category == 1) {

            this.fs.panelItem = item;
            this.$mdDialog.show({
                template: '<fi-material-dialog type="1" hide-button="true"></fi-material-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
            
        }
        else if (category == 2) {

            this.fs.legItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-leg-dialog hide-button="true"></fi-fe-leg-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });

        }
        else if (category == 3) {
            this.fs.handleItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-handle-dialog></fi-fe-handle-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
        }
        else if (category == 4) {
            this.fs.handleInternalItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-handle-internal-dialog></fi-fe-handle-internal-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
        }
    }

    removeMerge(column: IColumnItem, row: IRowItem) {

        for(const mw of row.merged_with) {
            for(const cr of column.rows) {
                if (cr.row_id == mw.row_id) {
                    cr.parent_row_id = 0;
                }
            }
            
        }

        row.merged = false;
        row.merged_with = [];

        // Delete from DB
        this.$http.post("https://www.fitit.lt/db/delete-merged-rows.php", { 

            row_id: row.id

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                //
        });

    }

    getMergedRowsHeight(column: IColumnItem, row: IRowItem) {

        let total = 0;

        if (row.merged) {

            for(const mw of row.merged_with) {

                for(const cr of column.rows) {

                    if (cr.row_id == mw.row_id) {
                        total = total + Number(cr.height);
                    }

                }

            }

            return total + row.inner_height;
            
        }

        
    }

    modifyRowHeight(activeColumn: IColumnItem, activeRow: IRowItem) {

        activeRow.height = activeRow.inner_height + this.fs.edgeWidth;
        activeRow.inner_height = activeRow.inner_height;

        let untouchables = 0;
        let untouchablesCount = 0;

        if (activeColumn.rowsCount == 1) {
            activeColumn.height = activeRow.height;
            activeColumn.outer_height = activeColumn.height + this.fs.furniture.plinth.height;
        }
        else {
            for(const row of activeColumn.rows) {
                // Get untouchables
                if (activeRow.order_no > row.order_no) {
                    untouchables = untouchables + row.height;
                    untouchablesCount = untouchablesCount + 1;
                }
                else if (activeRow.order_no < row.order_no) {
                    row.height = Number(((activeColumn.height - activeRow.height - untouchables) / (activeColumn.rowsCount - untouchablesCount - 1)).toFixed(1));
                    row.inner_height = Number((row.height - this.fs.edgeWidth).toFixed(1));
                }

                // console.log(row);

                //for(const rm of row.merged_with) {
                  //  if (rm.row.row_id == row.row_id) {
                    //    rm.row.height = row.height;
                      //  rm.row.inner_height = row.inner_height;

                    // console.log(rm.row.height);
                  //  }
               // }
            }
        }
 
    }

    canShowRows() {
        if (this.fs.furniture.type == 2 && (this.feps.activeExpansionPanel == 9 || this.feps.activeExpansionPanel == 10)) {
            return false;
        }
        else {
            return true;
        }
    }

    mergeRow(column: IColumnItem, row: IRowItem) {

       // console.log(row);
        // row.parent = this.fs.furniture.activeRow;
        row.parent_row_id = this.fs.furniture.activeRow.row_id;
        row.outer = 0;
        row.merged = false;

        this.fs.furniture.activeRow.merged = true;
        this.fs.furniture.activeRow.merged_with.push({
            row_height: row.height,
            row_width: row.width,
            row_id: row.row_id,
            order_no: row.order_no,
            parent_row_id: this.fs.furniture.activeRow.row_id,
            
        });

    }


    canMergeOLD(column: IColumnItem, row: IRowItem) {
        if (this.fs.furniture.activeColumn == column) {

            if (row.merged) {
                return false;
            }
            else if (row.parent_row_id == 0 && !this.fs.furniture.activeRow) {
            
                let last = 0;
                for(const el of this.fs.furniture.activeRow.merged_with) {
                    last = el.order_no;
                }
                if (row.order_no == (Number(last) + 1)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                if (row.order_no == (Number(this.fs.furniture.activeRow.order_no) + 1)) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        else {
            return false;
        }
    }

    canMerge(column: IColumnItem, row: IRowItem) {
        if (this.fs.furniture.activeRow) {
            if (this.fs.furniture.activeColumn == column) {

                // console.log('row_id: ' + row.row_id);
    
                // console.log('active_row: ' + this.fs.furniture.activeRow);
                // console.log('active_row_id: ' + this.fs.furniture.activeRow.row_id);
    
    
                if (row.merged || row.row_id == this.fs.furniture.activeRow.row_id) {
                    return false;
                }
                else if (row.parent_row_id == 0) {
                    if (row.order_no == (Number(this.fs.furniture.activeRow.order_no) + 1)) {
                        return true;
                    }
                    else {
                        let last = 0;
                        for(const el of this.fs.furniture.activeRow.merged_with) {
                            last = el.order_no;
                        }
    
                        if (row.order_no == 1) {
                            return false;
                        }
                        else if (row.order_no == Number(last) + 1) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                }
            }
            else {
                return false;
            }
        }
        
    }

    getInnerName(type: number) {
        if (type == 0) {
            return 'Tuščia lentyna';
        }
        else if (type == 1) {
            return 'Drabužių kabykla';
        }
        else if (type == 2) {
            return 'Dviguba kabykla';
        }
        else if (type == 3) {
            return 'Vertikalus atsiskyrimas';
        }
        else if (type == 4) {
            return 'Vidinis stalčius';
        }
        else if (type == 5) {
            return 'Stalčius dešinėje';
        }
        else if (type == 6) {
            return 'Stalčius kairėje';
        }
        else if (type == 7) {
            return 'Stalčius dvigubas';
        }
    }

    getOuterName(type: number) {
        if (type == 0) {
            return 'Be durelių';
        }
        else if (type == 1) {
            return 'Durys (Kairė)';
        }
        else if (type == 2) {
            return 'Durys (Dešinė)';
        }
        else if (type == 3) {
            return 'Durys (Dvigubos)';
        }
        else if (type == 4) {
            return 'Durys (Viršus)';
        }
        else if (type == 5) {
            return 'Durys (Apačia)';
        }
        else if (type == 6) {
            return 'Stalčius';
        }
    }

    getHandleName(type: number) {
        if (type == 0) {
            return 'Be rankenėlių';
        }
        else if (type == 1) {
            return 'Rankenėlės';
        }
        else if (type == 2) {
            return 'Įleidžiamos';
        }
        else if (type == 3) {
            return 'Nuo paspaudimo';
        }
    }

    getCount(type: string) {

        this.counter = 0;

        this.fs.furniture.columns.forEach((column: IColumnItem) => {
            column.rows.forEach((row: IRowItem) => {


                if (type == 'inner_drawers') {
                    if (row.inner == 4 || row.inner == 5 || row.inner == 6 ) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'inner_drawers_double') {
                    if (row.inner == 7) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'clothes') {
                    if (row.inner == 1) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'clothes_double') {
                    if (row.inner == 2) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'separations') {
                    if (row.inner == 3) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'doors') {
                    if (row.outer == 1 || row.outer == 2 || row.outer == 4 || row.outer == 5) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'doors_double') {
                    if (row.outer == 3) {
                        this.counter = this.counter + 1;
                    }
                }
                else if (type == 'outer_drawers') {
                    if (row.outer == 6) {
                        this.counter = this.counter + 1;
                    }
                }


                
            });
        });

        return this.counter;
    }
}
