import * as angular from 'angular';
// import * as uirouter from '@uirouter/angularjs';
import * as uirouter from 'angular-ui-router';
import { app } from '../app';

export default [
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',
    (
        $stateProvider: uirouter.StateProvider,
        $urlRouterProvider: uirouter.UrlRouterProvider,
        $locationProvider: angular.ILocationProvider,
    ) => {


        $stateProvider.state({
            name: 'home',
            url: '/',
            template: '<home></home>'
        });

        // $stateProvider.state({
            // name: 'home-request-page',
            // url: '/nestandartiniu-baldu-gamyba',
            // template: '<home-request-page></home-request-page>'
        // });

        $stateProvider.state({
            name: 'gallery-edit',
            url: '/galerija-redagavimas',
            template: '<gallery-edit></gallery-edit>'
        });

        $stateProvider.state({
            name: 'carpenter-edit',
            url: '/meistro-uzsakymai',
            template: '<carpenter-edit></carpenter-edit>'
        });

        $stateProvider.state({
            name: 'giftcards-edit',
            url: '/dovanu-kuponai',
            template: '<giftcards-edit></giftcards-edit>'
        });

        $stateProvider.state({
            name: 'discount',
            url: '/nuolaidu-kodai',
            template: '<discount-edit></discount-edit>'
        });

        $stateProvider.state({
            name: 'item',
            url: '/baldai/{itemId}',
            template: '<item-details></item-details>'
        });

        $stateProvider.state({
            name: 'konfiguratorius',
            url: '/konfiguratorius/{itemId}',
            template: '<configurator></configurator>'
        });

        $stateProvider.state({
            name: 'konfiguratorius2',
            url: '/konfiguratorius',
            template: '<configurator></configurator>'
        });

        $stateProvider.state({
            name: 'configuratornew',
            url: '/configuratornew',
            template: '<configuratornew></configuratornew>'
        });

        $stateProvider.state({
            name: 'baldai',
            url: '/baldai',
            template: '<item-list></item-list>'
        });

        $stateProvider.state({
            name: 'baldu-sarasas',
            url: '/baldu-sarasas',
            template: '<admin-items></admin-items>'
        });

        $stateProvider.state({
            name: 'medziagos',
            url: '/medziagos',
            template: '<admin-material></admin-material>'
        });

        $stateProvider.state({
            name: 'uzsakymai',
            url: '/uzsakymai',
            template: '<admin-orders></admin-orders>'
        });

        $stateProvider.state({
            name: 'vartotojai',
            url: '/vartotojai',
            template: '<admin-users></admin-users>'
        });


        $stateProvider.state({
            name: 'mano profilis',
            url: '/mano-profilis',
            template: '<user-profile></user-profile>'
        });

        $stateProvider.state({
            name: 'Meistro uzsakymas',
            url: '/meistro-uzsakymas',
            template: '<order-carpenter></order-carpenter>'
        });

        $stateProvider.state({
            name: 'Naujas pasiūlymas',
            url: '/offer',
            template: '<offer></offer>'
        });

        $stateProvider.state({
            name: 'Išklotinė',
            url: '/isklotine/{itemId}',
            template: '<documentation></documentation>'
        });


        $stateProvider.state({
            name: 'Pasiūlymas',
            url: '/offer/{orderNo}',
            template: '<offer></offer>'
        });

        $stateProvider.state({
            name: 'Dovanų čekis',
            url: '/dovanu-cekis',
            template: '<gift-card></gift-card>'
        });

        $stateProvider.state({
            name: 'Dovanų čekis užsakytas',
            url: '/dovanu-cekio-patvirtinimas',
            template: '<success-gift-card></success-gift-card>'
        });

        $stateProvider.state({
            name: 'isimintini-baldai',
            url: '/isimintini-baldai',
            template: '<user-favorites></user-favorites>'
        });

        $stateProvider.state({
            name: 'mano-uzsakymai',
            url: '/mano-uzsakymai',
            template: '<user-orders></user-orders>'
        });

        $stateProvider.state({
            name: 'order',
            url: '/order/{orderId}',
            template: '<order></order>'
        });

        $stateProvider.state({
            name: 'keisti-slaptazodi',
            url: '/keisti-slaptazodi',
            template: '<user-password></user-password>'
        });

        $stateProvider.state({
            name: 'krepselis',
            url: '/krepselis',
            template: '<cart></cart>'
        });

        $stateProvider.state({
            name: 'uzsakymas',
            url: '/uzsakymas',
            template: '<checkout></checkout>'
        });

        $stateProvider.state({
            name: 'success 2',
            url: '/meistro-uzsakymo-patvirtinimas',
            template: '<success-order-carpenter></success-order-carpenter>'
        });

        $stateProvider.state({
            name: 'success 3',
            url: '/apmokejimas-sekmingas',
            template: '<success-offer></success-offer>'
        });

        $stateProvider.state({
            name: 'success',
            url: '/thank-you',
            template: '<success></success>'
        });

        $stateProvider.state({
            name: 'view order',
            url: '/view-order/{orderId}',
            template: '<view-order></view-order>'
        });

        $stateProvider.state({
            name: 'prisijungimas',
            url: '/prisijungimas',
            template: '<login></login>'
        });

        $stateProvider.state({
            name: 'taisykles',
            url: '/taisykles',
            template: '<rules></rules>'
        });

        $stateProvider.state({
            name: 'galerija',
            url: '/galerija',
            template: '<gallery></gallery>'
        });

        $stateProvider.state({
            name: 'kontaktai',
            url: '/kontaktai',
            template: '<contacts></contacts>'
        });

        $stateProvider.state({
            name: 'privatumo-politika',
            url: '/privatumo-politika',
            template: '<privacy-policy></privacy-policy>'
        });

        $stateProvider.state({
            name: 'slapuku-politika',
            url: '/slapuku-politika',
            template: '<cookie-policy></cookie-policy>'
        });

        $stateProvider.state({
            name: 'siusti-uzklausa',
            url: '/siusti-uzklausa',
            template: '<request></request>'
        });

        $stateProvider.state({
            name: 'bendradarbiavimas',
            url: '/bendradarbiavimas',
            template: '<cooperation></cooperation>'
        });

        $stateProvider.state({
            name: 'duk',
            url: '/duk',
            template: '<duk></duk>'
        });

        $stateProvider.state({
            name: 'three',
            url: '/three',
            template: '<three></three>'
        });


        $stateProvider.state({
            name: 'kitchen',
            url: '/virtuve',
            template: '<kitchen></kitchen>'
        });

        // Demo (delete)
        $stateProvider.state({
            name: 'demo',
            url: '/demo',
            template: '<demo></demo>'
        });

        // $urlRouterProvider.otherwise('/');
        $locationProvider.hashPrefix('');
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false,
            rewriteLinks: true
        });
        
    }
];