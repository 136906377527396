import './FiConfiguratorSettings.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiExpansionPanelService } from './../fiExpansionPanel/FiExpansionPanelService';
import { IColumnItem, IPlinth, iMDFItem, FiFurnitureService, IRowItem, IFurnitureItem, IMergedRows, IDivideRows, ISlidingDoorItem, iFillingItem, iPanelItem, iPoint } from './../fiFurniture/FiFurnitureService';
import { FiCart, ICartItem } from '../../services/Cart';
import { FiPrice } from '../../services/Price';
// import * as uirouter from '@uirouter/angularjs';
import * as uirouter from 'angular-ui-router';
import { isEmptyObject } from 'jquery';

@Component({
    controllerAs: 'vm',
    template: require('./FiConfiguratorSettings.html'),
    bindings: {
        activeStep: '<',
    },
})

export class FiConfiguratorSettings {
    static $inject = [
        'fiExpansionPanelService',
        'fiFurnitureService',
        'fiCart',
        '$mdDialog',
        'fiPrice',
        '$window',
        '$http',
        '$state',
        '$scope',
        '$location',
    ];

    constructor(
        private feps: FiExpansionPanelService,
        private fs: FiFurnitureService,
        private cart: FiCart,
        private $mdDialog: angular.material.IDialogService,
        private ps: FiPrice,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
        private $state: uirouter.StateService,
        private $scope: angular.IScope,
        private $location: angular.ILocationService,
    ) {
    }

    columns: IColumnItem[] = [];
    slidingDoors: ISlidingDoorItem[] = [];
    rows: IRowItem[] = [];
    
    
    
    freeConsultation: boolean = true;

    cartItem: ICartItem[];
    itemCartCount: number = 1;
    rowsCount: number = 1;
    column = [];
    
    lmdp = [];
    legs = [];
    handles = [];
    code: string;
    cartStorage: FiCart;
    showCartLink: boolean = false;
    item_id: string;
    column_id: number;
    door_id: number;
    door_no: number;
    row_id: number;
    column_no: number;
    row_no: number;
    editMode: boolean;
    newItem: boolean;

    // Item calculation
    priceTotal: number;
    test: any;

    all: any;

    showWall: boolean = false;

    // Test ( Remove )
    hideForDemo: boolean = false;

    help = [
        {
            id: 1,
            text: '<p>Pasirinkite pageidaujamą baldo tipą.</p>'
        },
        {
            id: 2,
            text: '<p><strong>Laisvai pastatomą</strong> baldą galima perkelti iš vienos vietos į kitą. Tai ypač patogu, kai nesate tikri dėl baldo stovėjimo vietos.</p><p><strong>Laisvai pastatomi baldai nišoje</strong> yra gamykliškai pagaminti baldai su pilnais šonais ir galine sienele. Tarpai tarp spintos šonų ir sienų gali būti 5-7 mm. Tokie baldai puikiai tinka tuose vietose, kur sienos/lubos/grindys neturi kreivumo. <u>Atvyksime į vietą patikslinti matmenis.</u></p><p><strong>Įmontuojami</strong> baldai yra tvirtinami prie sienų, lubų ar grindų. Tokie baldai yra „gaminami“ vietoje, nes kiekviena detalė yra pjaunama ir pritaikoma pagal konkrečius matmenis. Įmontuojami baldai paprastai neturi pilnų šoninių sienų (lentynos tvirtinamos tiesiai į sieną) ir galinės nugarėlės. <u>Atvyksime į vietą patikslinti matmenis.</u></p><p><strong>Pakabinami</strong> baldai tokie baldai, kurių galinė dalis tvirtinama prie sienos. <u>Atvyksime į vietą patikslinti matmenis</u>, jei baldas bus pakabinamas nišoje.</p>'
        },
        {
            id: 3,
            text: '<p>Nurodykite išorinius baldo išmatavimus.</p><p>SVARBU: Įsitikinkite, kad užsakomas baldas tilps numatytoje vietoje. Daugiau informacijos „Matavimo instrukcijose“.</p>'
        },
        {
            id: 4,
            text: '<p>Nurodykite vertikalių sekcijų skaičių.</p><p>DĖMESIO: Galite pakeiskite vertikalių sekcijų išorinį aukštį, jei kuriate „laiptuotą“ baldą.</p>'
        },
        {
            id: 5,
            // text: '<p>Rinkitės pagrindą su „Padu“ arba „Kojelėmis“, jei apatinis skyrius bus su varstomomis durimis arba stalčiais.</p><p>„Plokštė“ ir „Be pagrindo“ tinkamas pasirinkimas spintoms su slankiojančiomis durimis, atviroms lentynoms ir pan.</p><p>Pagrindo aukštis įeina į bendrą baldo aukštį, nurodytą ankstesniame žingsnyje.</p>'
            text: '<p>Rinkitės pagrindą su „Padu“, „Cokoliu“ arba „Kojelėmis“, jei apatinis skyrius bus su varstomomis durimis arba stalčiais.</p><p>„Plokštė“ ir „Be pagrindo“ tinkamas pasirinkimas spintoms su slankiojančiomis durimis, atviroms lentynoms ir pan.</p><p>Baldo su „Padu“ šonai yra iki pat grindų. Puikiai tinkantis pasirinkimas, kai yra matomas šonas.</p><p>Baldo su „Cokoliu“ arba „Kojelėmis“ šonai nesiekia grindų, todėl tinka baldams nišose, kur baldas turi būti iškeltas virš grindjuosčių.</p><p>Pagrindo aukštis įeina į bendrą baldo aukštį, nurodytą ankstesniame žingsnyje.</p>'
        },
        {
            id: 6,
            text: '<p>Nurodykite vidinių skyrių skaičių kiekvienoje sekcijoje. Čia taip pat galite keisti vidinių skyrių aukštį.</p><p>SVARBU: Jei nežinote tikslaus norimo lentynų aukščio ir norėsite lentynų aukštį keisti patys, žingsnyje „Papildomos paslaugos“ tai pažymėkite, mes išgręšime daugiau skylučių.</p>'
        },
        {
            id: 7,
            text: '<p>Pasirinkite labiausiai patinkančią LMDP plokštę. Iš pasirinktos plokštės bus gaminamas baldo karkasas ir vidiniai skyriai.</p>'
        },
        {
            id: 8,
            text: '<p>Užpildykite vidinius skyrius, kabyklomis, vidiniais stalčiais ar palikite tuščias lentynas.</p><p>SVARBU: Tais atvejais, kai sistema neleidžia pasirinkti bent vieno iš pasirinkimo, reiškia, kad toks pasirinkimas negalimas dėl per didelių arba per mažų vidinio skyriaus matmenų.</p><p>REKOMENDACIJOS: Žemiau nurodome rekomenduojamus vidinių stalčių ir kabyklų aukščius:</p><ul><li>Vidiniai stalčiai: aukštis 28 cm.</li><li>Drabužių kabykla: aukštis bent 95 cm.</li><li>Dviguba drabužių kabykla: aukštis bent 190 cm.</li></ul>'
        },
        {
            id: 9,
            text: '<p>Nurodykite, kur balde bus durelės, išoriniai stalčiai ar tiesiog atviros lentynos.</p>'
        },
        {
            id: 9,
            text: '<p>Šiame žingsnyje turite galimybę pasirinkti kitą nei karkasui naudojamą plokštę ar kitokį durų užpildą.</p><p>SVARBU: Pasirinkus skirtingas plokštes skirtingoms durelėms, baldo kaina gali ženkliai padidėti. Todėl visiems fasado elementams rekomenduojame rinktis tą pačią plokštę.</p>'
        },
        {
            id: 10,
            text: '<p>Pasirinkite rankenėles iš sąrašo.</p><p>SVARBU: Pasirinkite rankenėles kiekvienoms durelės ar išoriniams stalčiams.</p>'
        },
        {
            id: 11,
            text: '<p><strong>Durelės dengia briaunas.</strong> Durelės uždengs priekines baldo briaunas, todėl briaunos nebus matomos. Šis pasirinkimas suteiks Jūsų baldui „lengvumo“.</p><p><strong>Matosi visos.</strong> Durelės bus įleistos į vidų, todėl visos baldo briaunos bus matomos. Šis pasirinkimas suteiks Jūsų baldui „solidumo“.</p><p><strong>Matosi viršus ir apačia.</strong> Durelės uždengs šonines ir vidines briaunas, tačiau viršutinės ir apatinės briaunos bus matomos. Šis pasirinkimas labiau tinkamas baldams su kojelėmis.</p><p><strong>Matosi viršus.</strong> Durelės uždengs apatinę, šonines ir vidines briaunas, tačiau viršutinė briauna bus matoma. Baldas su stogeliu yra tinkamas pasirinkimas baldams su padu.</p><p><strong>Matosi išorinės.</strong> Durelės uždengs vidines briaunas, tačiau viršutinė, apatinė ir šoninės briaunos bus matomos. Šis pasirinkimas įrėmins Jūsų susikurtą baldą.</p>'
        },
        {
            id: 12,
            text: '<p><strong>Galinė sienelė</strong> yra naudojame daugumoje baldų. Nepaisant to, kad ji yra itin plona, ji suteikia baldui papildomo stabilumo. Todėl rekomenduojame galinę sienelę pasirinkti. Galinės sienelės galite nesirinkti tam tikrais atvejais. Pavyzdžiui, jei Jūsų baldas bus pritvirtintas prie sienos.</p><p><strong>Grindjuostės išpjovimas</strong> yra reikalingas tam, kad neliktų tarpo tarp baldo ir galinės sienos už jo. Baldo gale apačioje išpjaunama stačiakampio forma apgaubianti grindjuostę. Taip baldas priglunda prie sienos.</p><p>Papildomos <strong>Lentynų skylutės</strong> suteikia galimybę keisti lentynų aukštį patiems. Mes išgręšime papildomų skylučių spintos vidinėje dalyje, kad jūs lengvai galėtumėte keisti lentynų aukštį.</p>'
        },
        {
            id: 13,
            text: '<p>Jei reikia kelių vienodų baldų, nurodykite, kelių baldų reikia.</p>'
        },
        {
            id: 14,
            text: '<p>Jei baigėte kurti baldą, pridėkite jį į krepšelį ir tęskite pirkimo procesą.</p>'
        },
        {
            id: 15,
            text: '<p><strong>Ekonominė.</strong> Šis pasirinkimas tinkamas kuriantiems pigesnį baldą. Ekonominės klasės baldų furnitūra bus kokybiška ir ilgaamžė. Tačiau ji neturės tų savybių, kurias turi naujausi lyderiaujančių gamintojų gaminiai.</p><p>Baldų furnitūra bus be tylaus uždarymo funkcijos. Jūsų baldu labiausiai tinkančią furnitūrą parinksime iš tokių gamintojų kaip: FGV (Italija), GTV (Lenkija) ar pan.</p><p>Stalčių bėgeliai – balde bus naudojami ritininiai (su ratukais) arba guoliniai stalčių bėgeliai. Abiejų rūšių bėgeliai montuojami stalčiaus šone.</p><p><strong>Premium.</strong> Šis pasirinkimas tinkamas ieškantiems optimalaus komforto ir kainos santykio. Baldo furnitūra bus ilgaamžė ir atitiks visus Jūsų šiandieninius poreikius. Šios klasės furnitūra apima lyderiaujančių gamintojų produkciją.</p><p>Baldų furnitūra bus su tylaus uždarymo funkcija. Jūsų baldu labiausiai tinkančią furnitūrą parinksime iš tokių gamintojų kaip: FGV (Italija), GTV (Lenkija) ar pan.</p><p>Stalčių bėgeliai – balde bus naudojami guoliniai arba paslėptieji stalčių bėgeliai. Guoliniai stalčių bėgeliai montuojami stalčiaus šone, o paslėptieji stalčiaus apačioje.</p><p><strong>Premium+.</strong> Šis pasirinkimas tinkamas kuriantiems aukščiausio komforto baldą. Baldo furnitūra bus ilgaamžė ir viršys Jūsų šiandieninius poreikius. Šios klasės furnitūra apima pažangiausią baldų furnitūrą iš inovatyviausių ir stipriausių gamintojų.</p><p>Baldų furnitūra bus su tylaus uždarymo funkcija. Jūsų baldu labiausiai tinkančią furnitūrą parinksime iš tokių gamintojų kaip: Grass (Austrija), Blum (Austrija).</p><p>Stalčių bėgeliai – balde bus naudojami guoliniai arba paslėptieji stalčių bėgeliai. Guoliniai stalčių bėgeliai montuojami stalčiaus šone, o paslėptieji stalčiaus apačioje.</p>'
        }
    ];

    legs_items = [];
    legs_results = [];

    lmdp_items = [];
    lmdp_results = [];

    handles_items = [];
    handles_results = [];

    handles_internal_items = [];
    handles_internal_results = [];

    mirrors_items = [];
    mirrors_results = [];

    knob_profiles_items = [];
    knob_profiles_results = [];

    points_items = [];

    filter = {
        lmdp: {
            color_tone: 0,
            color: 0,
            gloss: 0,
            roughness: 0,
            pattern: 0,
            manufacturer: 0,
            supplier: 0
        },
        legs: {
            color: 0,
            material: 0,
            height: 0,
            adjustable_height: 0
        },
        handle: {
            color: 0,
            length_category: 0,
            texture: 0,
            material: 0
        },
        handle_internal: {
            color: 0,
            length_category: 0,
            texture: 0,
            material: 0
        },
        knob_profiles: {
            color: 0,
            price_indicative: 0
        }
    };

    maxColumns: number;
    maxDoors: number;
    private user: any;

    $onInit() {

        this.item_id = this.$state.params['itemId'];
        this.newItem = this.item_id == 'new';
        
        this.user = JSON.parse(localStorage.getItem('currentUser'));

        if (this.newItem) {
            this.editMode = true;
            this.fs.selectFurniture('FIT86378426', true);
        }
        else if (this.$location.search().edit == '1' && this.item_id) {
            this.editMode = true;
            this.fs.selectFurniture(this.item_id, false);
        }
        else if (!this.$location.search().edit && this.item_id) {
            this.editMode = false;
            this.fs.selectFurniture(this.item_id, false);
        }
        else if (!this.fs.furniture) {
            this.editMode = false;
            this.fs.selectFurniture('FIT86378426', false);
        }
        else {
            this.editMode = false;
        }

        this.$scope.$watch(() => this.feps.activeExpansionPanel, () => this.setFirstActive());

        // LMDP
        this.$http.get('https://www.fitit.lt/db/lmdp.php').then((data) => {
            this.lmdp_items.push(data.data);
            this.lmdp_results = this.lmdp_items[0];
        });

        // legs
        this.$http.get('https://www.fitit.lt/db/legs.php').then((data) => {
            this.legs_items.push(data.data);
            this.legs_results = this.legs_items[0];
        });
        
        // handles
        this.$http.get('https://www.fitit.lt/db/handles.php').then((data) => {
            this.handles_items.push(data.data);
            this.handles_results = this.handles_items[0];
        });

        // handles internal
        this.$http.get('https://www.fitit.lt/db/handles_internal.php').then((data) => {
            this.handles_internal_items.push(data.data);
            this.handles_internal_results = this.handles_internal_items[0];
        });

        // mirrors
        this.$http.get('https://www.fitit.lt/db/mirrors.php').then((data) => {
            this.mirrors_items.push(data.data);
            this.mirrors_results = this.mirrors_items[0];
        });

        // knob_profiles
        this.$http.get('https://www.fitit.lt/db/knob_profiles.php').then((data) => {
            this.knob_profiles_items.push(data.data);
            this.knob_profiles_results = this.knob_profiles_items[0];
        });

        // points
        this.$http.post("https://www.fitit.lt/db/points.php", { 
            item_id: this.item_id,
        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {
                this.points_items.push(response.data);
                this.fs.furniture.points = this.points_items[0][0];
        });

        console.log('points_results: ' + this.fs.furniture.points);
      
        // console.log(this.fs.furniture)

        // console.log(this.fs.furniture.wall_width);
 
    }

    getMaxColumnsCount() {
        this.maxColumns = Math.floor(this.fs.furniture.width / 10);
        return this.maxColumns;
    }

    editPoint(point: iPoint) {
        
        this.fs.point = point;
        this.$mdDialog.show({
            template: '<fi-item-point-dialog></fi-item-point-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });

    }

    getWallWidthMin() {
        return this.fs.furniture.width;
    }

    addPoint() {
        this.fs.point = null;
        this.$mdDialog.show({
            template: '<fi-item-point-dialog></fi-item-point-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    getMaxSlidingDoorsCount() {
        this.maxDoors = Math.floor(this.fs.furniture.width / 40);
        return this.maxDoors;
    }

    getFillingsCount() {
        let count = 0;
        for(const column of this.fs.furniture.columns) {
            for(const row of column.rows) {
                if (row.is_mdf == 1) {
                    count = count + 1;
                }
            }
        }

        return count;
    }

    setFirstActive() {

        // this.feps.activeExpansionPanel == 8 || 
        // this.feps.activeExpansionPanel == 9 || 
        
        if (this.feps.activeExpansionPanel == 10 ||
            this.feps.activeExpansionPanel == 11) {

                this.fs.furniture.activeColumn = null;
                this.fs.furniture.activeRow = null;

                for(const column of this.fs.furniture.columns) {

                    if (!this.fs.furniture.activeColumn) {
                        this.fs.furniture.activeColumn = column;
                        column.active = true;
                        for(const row of column.rows) {
                            if (!this.fs.furniture.activeRow) {
                                this.fs.furniture.activeRow = row;
                                row.active = true;
                            }
                        }
                    }
                    else {
                        column.active = false;
                        for(const row of column.rows) {
                            row.active = false;
                        }
                    }
                }

                if (this.fs.furniture.type == 2) {

                    this.fs.furniture.activeDoor = null;
                    this.fs.furniture.activeDoorRow = null;
                    for(const door of this.fs.furniture.slidingDoors) {

                        if (!this.fs.furniture.activeDoor) {
                            this.fs.furniture.activeDoor = door;
                            door.active = true;
                        }
                        else {
                            door.active = false;
                        }

                        for(const row of door.divideRows) {
                            if (!this.fs.furniture.activeDoorRow) {
                                this.fs.furniture.activeDoorRow = row;
                                row.active = true;
                            }
                            else {
                                row.active = false;
                            }
                        }
                    }
                }
        }
        else if (this.feps.activeExpansionPanel == 4 || this.feps.activeExpansionPanel == 6) {
            this.fs.furniture.activeColumn = null;
            for(const column of this.fs.furniture.columns) {
                if (!this.fs.furniture.activeColumn) {
                    this.fs.furniture.activeColumn = column;
                    column.active = true;
                }
                else {
                    column.active = false;
                }
            }
        }
        
    }

    applyFilter(filterCategory: number) {

        if (filterCategory == 1) {
            this.legs_results = this.legs_items[0];

            if (this.filter.legs.color) {
                this.legs_results = this.legs_results.filter(item => item.color == this.filter.legs.color );
            }

            if (this.filter.legs.height) {
                this.legs_results = this.legs_results.filter(item => item.height == this.filter.legs.height );
            }

            if (this.filter.legs.material) {
                this.legs_results = this.legs_results.filter(item => item.material == this.filter.legs.material );
            }

            if (this.filter.legs.adjustable_height) {
                this.legs_results = this.legs_results.filter(item => item.adjustable_height == this.filter.legs.adjustable_height );
            }

        }
        else if (filterCategory == 2) {

            this.lmdp_results = this.lmdp_items[0];

            if (this.filter.lmdp.color_tone) {
                this.lmdp_results = this.lmdp_results.filter(item => item.color_tone == this.filter.lmdp.color_tone );
            }
            
            if (this.filter.lmdp.color) {
                this.lmdp_results = this.lmdp_results.filter(item => item.color == this.filter.lmdp.color );
            }

            if (this.filter.lmdp.gloss) {
                this.lmdp_results = this.lmdp_results.filter(item => item.gloss == this.filter.lmdp.gloss );
            }
            
            if (this.filter.lmdp.roughness) {
                this.lmdp_results = this.lmdp_results.filter(item => item.roughness == this.filter.lmdp.roughness );
            }

            if (this.filter.lmdp.pattern) {
                this.lmdp_results = this.lmdp_results.filter(item => item.pattern == this.filter.lmdp.pattern );
            }
            
            if (this.filter.lmdp.supplier) {
                this.lmdp_results = this.lmdp_results.filter(item => item.supplier == this.filter.lmdp.supplier );
            }
        }
        else if (filterCategory == 3) {
            this.handles_results = this.handles_items[0];

            if (this.filter.handle.color) {
                this.handles_results = this.handles_results.filter(item => item.color == this.filter.handle.color );
            }

            if (this.filter.handle.length_category) {
                this.handles_results = this.handles_results.filter(item => item.length_category == this.filter.handle.length_category );
            }

            if (this.filter.handle.texture) {
                this.handles_results = this.handles_results.filter(item => item.texture == this.filter.handle.texture );
            }

            if (this.filter.handle.material) {
                this.handles_results = this.handles_results.filter(item => item.material == this.filter.handle.material );
            }
        }
        else if (filterCategory == 4) {
            
            this.handles_internal_results = this.handles_internal_items[0];

            if (this.filter.handle_internal.color) {
                this.handles_internal_results = this.handles_internal_results.filter(item => item.color == this.filter.handle_internal.color );
            }

            if (this.filter.handle_internal.length_category) {
                this.handles_internal_results = this.handles_internal_results.filter(item => item.length_category == this.filter.handle_internal.length_category );
            }

            if (this.filter.handle_internal.texture) {
                this.handles_internal_results = this.handles_internal_results.filter(item => item.texture == this.filter.handle_internal.texture );
            }

            if (this.filter.handle_internal.material) {
                this.handles_internal_results = this.handles_internal_results.filter(item => item.material == this.filter.handle_internal.material );
            }
        }
        else if (filterCategory == 5) {
            this.knob_profiles_results = this.knob_profiles_items[0];

            if (this.filter.knob_profiles.color) {
                this.knob_profiles_results = this.knob_profiles_results.filter(item => item.color == this.filter.knob_profiles.color );
            }

            if (this.filter.knob_profiles.price_indicative) {
                this.knob_profiles_results = this.knob_profiles_results.filter(item => item.price_indicative == this.filter.knob_profiles.price_indicative );
            }
        }
        
    }

    createNew() {
        location.reload();
    }

    unActivateRow() {
        this.fs.furniture.columns.forEach((c: IColumnItem) => {
            c.active = false;
            c.rows.forEach((r: IRowItem) => {
                    r.active = false;
                
            });
        });
        this.fs.furniture.activeRow = null;
        this.fs.furniture.activeColumn = null;
    }

    hasDoors() {
        let hasDoors = false;
        for(const column of this.fs.furniture.columns) {
            for(const row of column.rows) {
                if (row.outer != 0) {
                    hasDoors = true;
                }
            }
        }
        return hasDoors;
    }

    goNext(panel:number, view:number) {

        this.unActivateRow();

        if (panel == 10) {

            if (this.fs.furniture.type != 2) {
                let hasDoors = false;

                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        if (row.outer != 0) {
                            hasDoors = true;
                        }
                    }
                }
    
                if (hasDoors) {
                    this.feps.expand(panel);
                    this.fs.activeStep = view; 
                }
                else {
                    this.feps.expand(13);
                    this.fs.activeStep = 4; 
                }
            }
            else {
                this.feps.expand(10);
                this.fs.activeStep = 3; 
            }
            
        }
        else {
            this.feps.expand(panel);
            this.fs.activeStep = view; 
        }
        
    }

    editModeNav(page: number) {
        this.fs.activeView = page;
        this.feps.activeExpansionPanel = 0;
    }

    modifyColumnHeight(outerHeight: number) {
        this.fs.furniture.activeColumn.height = outerHeight - this.fs.furniture.plinth.height;
        this.fs.furniture.activeColumn.outer_height = outerHeight;

        for(const row of this.fs.furniture.activeColumn.rows) {
            row.height = this.fs.furniture.activeColumn.height / this.fs.furniture.activeColumn.rowsCount;
        }

        this.fs.furniture.height = Math.max.apply(Math, this.fs.furniture.columns.map(function(c) { return c.outer_height; }));
       
    }

    getMaxRowHeight() {
        let untouchables = 0;
        let maxHeight = 0;
        let canModifyRowsCount = 0;
        for(const row of this.fs.furniture.activeColumn.rows) {
            if (this.fs.furniture.activeRow.order_no < row.order_no) {
                canModifyRowsCount = canModifyRowsCount + 1;
            }
        }

        for(const row of this.fs.furniture.activeColumn.rows) {
            if (this.fs.furniture.activeRow.order_no > row.order_no) {
                untouchables = untouchables + row.height;
            }
            else if (this.fs.furniture.activeRow.order_no < row.order_no) {
                maxHeight = this.fs.furniture.activeColumn.height - untouchables - ((this.fs.furniture.activeColumn.rowsCount - canModifyRowsCount + 1) * 18)
            }
        }

        return maxHeight;
    }

    modifyColumnWidth(newWidth: number) {

        //let allColumnsWidth = 0;
        //for(const column of this.fs.furniture.columns) {
          //  if (column != this.fs.furniture.activeColumn) {
            //    allColumnsWidth = allColumnsWidth + column.width;
           // }
        //}
        // if (allColumnsWidth + newWidth < 600) {

        this.fs.furniture.activeColumn.width = newWidth + ((this.fs.furniture.columnsCount + 1) * this.fs.edgeWidth) / this.fs.furniture.columnsCount;
        this.fs.furniture.activeColumn.inner_width = newWidth;

        // console.log(this.fs.furniture.activeColumn.width);
        // console.log(this.fs.furniture.activeColumn.inner_width);

        for(const row of this.fs.furniture.activeColumn.rows) {
            row.width = newWidth + this.fs.edgeWidth;
            row.inner_width = newWidth;
        }

        let newFurnitureWidht = 0;
        for(const column of this.fs.furniture.columns) {
            // console.log('column width:' + column.width);
            newFurnitureWidht = newFurnitureWidht + column.width;
        }

        this.fs.furniture.width = newFurnitureWidht;
    }

    modifySlidingDoors(slidingDoorsCount) {
        if (slidingDoorsCount < 1) {
            return;
        }

        this.slidingDoors = [];
        this.fs.furniture.slidingDoorsCount = slidingDoorsCount;

        this.door_no = 0;
        

        for(var i = 0; i < this.fs.furniture.slidingDoorsCount; i++) {

            this.door_id = Math.floor(Math.random() * 89999999999 + 10000000000);
            this.row_id = Math.floor(Math.random() * 89999999999 + 10000000000);

            this.row_no = 0;

            this.slidingDoors.push( {
                id: 0,
                order_no: this.door_no = this.door_no + 1,
                door_id: this.door_id,
                active: false,
                width: this.fs.furniture.width / slidingDoorsCount,
                height: this.fs.furniture.height - this.fs.furniture.plinth.height,
                divide: 0,
                divideRows: [{
                    id: 0,
                    row_id: this.row_id,
                    order_no: this.row_no = this.row_no + 1,
                    panel_id: this.fs.furniture.material.id,
                    mirror_id: 0,
                    knob_id: 0,
                    width: this.fs.furniture.width / slidingDoorsCount,
                    height: this.fs.furniture.height - this.fs.furniture.plinth.height,
                    panel_type: this.fs.furniture.material_is_mdf === true ? 1 : 0,
                    active: false,
                    panel: this.fs.furniture.material,
                    mirror: null,
                    knob: null,
                    filling_id: 0,
                    filling: null,
                    fillings: false,
                    mdf: null,
                    mdf_gloss: 0,
                    mdf_id: 0,
                    handle_type: 0,
                    mdf_thickness: 0,
                    handle_id: 0,
                    handle_internal_id: 0,
                    milled_knob_id: 0,
                    handle_internal: null,
                    milled_knob: null,
                    handle: null
                }]
            })
        }

        this.fs.furniture.slidingDoors = this.slidingDoors;
        this.fs.furniture.activeDoorRow = null;
        this.fs.furniture.activeDoor = null;

    }

    modifyColumns(columnsCount: number) {
        if (columnsCount < 1) {
            return;
        }

        this.columns = [];
        this.fs.furniture.columnsCount = columnsCount;
        

        this.column_no = 0;
        this.row_no = 0;
        for(var i = 0; i < this.fs.furniture.columnsCount; i++) {

            this.column_id = Math.floor(Math.random() * 89999999999 + 10000000000);
            this.row_id = Math.floor(Math.random() * 89999999999 + 10000000000);

            this.columns.push(
                {
                    id: 0,
                    order_no: this.column_no = this.column_no + 1,
                    column_id: this.column_id,
                    width: this.fs.furniture.width / columnsCount,
                    inner_width: (this.fs.furniture.width - ((columnsCount + 1) * this.fs.edgeWidth)) / columnsCount,
                    height: this.fs.furniture.height - this.fs.furniture.plinth.height,
                    outer_height: this.fs.furniture.height,
                    active: false,
                    rowsCount: 1,
                    rows: [{
                        id: 0,
                        row_id: this.row_id,
                        order_no: this.row_no = this.row_no + 1,
                        width: this.fs.furniture.width / columnsCount,
                        inner_width: (this.fs.furniture.width - ((columnsCount + 1) * this.fs.edgeWidth)) / columnsCount,
                        height: this.fs.furniture.height - this.fs.furniture.plinth.height,
                        inner_height: this.fs.furniture.height - this.fs.edgeWidth - this.fs.furniture.plinth.height,
                        active: false,
                        inner: 0,
                        outer: 0,
                        handleType: 0,
                        handle: null,
                        handle_internal: null,
                        panel: null,
                        handle_id: 0,
                        milled_knob_id: 0,
                        milled_knob: null,
                        handle_internal_id: 0,
                        panel_id: 0,
                        merged: false,
                        parent_row_id: 0,
                        parent: null,
                        merged_with: [],
                        panelType: 0,
                        is_mdf: false,
                        fillings: false,
                        mdf_id: 0,
                        filling_id: null,
                        filling: null,
                        mdf: null,
                        mdf_thickness: 0,
                        mdf_gloss: 0
                    }]
                }
            );
            this.row_no = 0;
        }
        this.fs.furniture.columns = this.columns;

        this.fs.furniture.activeColumn = null;
        this.fs.furniture.activeRow = null;
        // this.ps.getPrice();
    }

    setRails(type: number) {
        this.fs.furniture.rails = type;

        if (type == 1) {
            this.fs.furniture.edges = 6;
        }
        else if (type == 2) {
            this.fs.furniture.edges = 8;
        }
        else if (type == 3) {
            this.fs.furniture.edges = 10;
        }
    }

    modifyFurnitureHeight(newHeight: number) {
        this.fs.furniture.height = newHeight;
        for(const column of this.fs.furniture.columns) {
            column.outer_height = newHeight;
            column.height = newHeight - this.fs.furniture.plinth.height

            for(const row of column.rows) {
                row.height = (newHeight - this.fs.furniture.plinth.height) / column.rowsCount;
                row.inner_height = (newHeight - this.fs.furniture.plinth.height - this.fs.edgeWidth) / column.rowsCount
            }
        }

        if (this.fs.furniture.type == 2) {
            this.modifySlidingDoors(this.fs.furniture.slidingDoorsCount);
        }
        
    }

    setNoHandles() {

        if (this.fs.sameNoHandles) {

            this.fs.furniture.activeRow.handleType = 0;
            this.fs.furniture.activeRow.handle = null;
            this.fs.furniture.activeRow.handle_id = 0;

            for(const column of this.fs.furniture.columns) {
                for(const row of column.rows) {
                    row.handle = null;
                    row.handle_id = 0;
                }
            }
        }
        
    }

    setPushHandles() {
        if (this.fs.samePushHandles) {

            this.fs.furniture.activeRow.handleType = 3;
            this.fs.furniture.activeRow.handle = null;
            this.fs.furniture.activeRow.handle_id = 0;

            for(const column of this.fs.furniture.columns) {
                for(const row of column.rows) {
                    row.handle = null;
                    row.handle_id = 0;
                }
            }
        }
    }

    setRearWall() {
        if (this.fs.furniture.additional.option_1) {
            this.$mdDialog.show({
                template: '<fi-rearwall-dialog></fi-rearwall-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
        }
        else {
            this.fs.furniture.additional.option_1 = true;
        }
    }

    rearWallActive() {
      
        // if (this.fs.furniture.installationType == 3) {
            //return false;
        // }
        // else {
           // return true;
        // }

        return true;
        
        //if (this.fs.furniture.type == 2 && (this.fs.furniture.installation == 3 || this.fs.furniture.installationType == 4) && this.fs.furniture.rails == 1) {
          //  return false;
        //}
        //else {
          //  return true;
        //}
    }

    modifyFurnitureWidth(newWidth: number) {

        // console.log(newWidth);
        
        this.fs.furniture.width = newWidth;
        for(const column of this.fs.furniture.columns) {
            column.width = newWidth / this.fs.furniture.columnsCount;
            column.inner_width = (this.fs.furniture.width - ((this.fs.furniture.columnsCount + 1) * this.fs.edgeWidth)) / this.fs.furniture.columnsCount;

            for(const row of column.rows) {
                row.width = column.width;
                row.inner_width = (this.fs.furniture.width - ((this.fs.furniture.columnsCount + 1) * this.fs.edgeWidth)) / this.fs.furniture.columnsCount;
            }
        }

        if (this.fs.furniture.type == 2) {
            for(const sd of this.fs.furniture.slidingDoors) {
                sd.width = this.fs.furniture.width / this.fs.furniture.slidingDoorsCount;

                for(const dr of sd.divideRows) {
                    dr.width = sd.width;
                }
            }

            this.modifySlidingDoors(this.fs.furniture.slidingDoorsCount);

        }
    }

    modifyRows(rowsCount: number) {
        if (rowsCount < 1 || rowsCount > 19) {
            return;
        }

        this.rows = [];
        this.rowsCount  = rowsCount;

        this.row_no = 0;

        for(var i = 0; i < this.rowsCount; i++) {
            
            this.row_id = Math.floor(Math.random() * 89999999999 + 10000000000);

            this.rows.push(
                {
                    id: 0,
                    row_id: this.row_id,
                    order_no: this.row_no = this.row_no + 1,
                    width: this.fs.furniture.activeColumn.width,
                    inner_width: this.fs.furniture.activeColumn.inner_width,
                    height: Number((this.fs.furniture.activeColumn.height / rowsCount).toFixed(1)),
                    inner_height: Number(((this.fs.furniture.activeColumn.height - (this.fs.edgeWidth * rowsCount)) / rowsCount).toFixed(1)),
                    active: false,
                    inner: 0,
                    outer: 0,
                    handleType: 0,
                    handle: null,
                    handle_internal: null,
                    handle_internal_id: 0,
                    panel: null,
                    handle_id: 0,
                    milled_knob_id: 0,
                    milled_knob: null,
                    panel_id: 0,
                    merged: false,
                    parent_row_id: 0,
                    parent: null,
                    merged_with: [],
                    panelType: 0,
                    is_mdf: false,
                    fillings: false,
                    filling: null,
                    mdf_id: 0,
                    filling_id: null,
                    mdf: null,
                    mdf_thickness: 0,
                    mdf_gloss: 0
                }
            );
        }

        this.fs.furniture.activeColumn.rowsCount = rowsCount;
        this.fs.furniture.activeColumn.rows = this.rows;

    }

    getRowMDFPrice(mdf: iMDFItem, row: IRowItem) {
        if (row.mdf_gloss == 0) {
            if (row.mdf_thickness == 0) {
                return mdf.price_matte_1_side;
            }
            else if (row.mdf_thickness == 1) {
                return Number(mdf.price_matte_1_side) + 4;
            }
            else if (row.mdf_thickness == 2) {
                return Number(mdf.price_matte_1_side) + 5.57;
            }
        }
        else if (row.mdf_gloss == 1) {
            if (row.mdf_thickness == 0) {
                return mdf.price_matte_2_side;
            }
            else if (row.mdf_thickness == 1) {
                return Number(mdf.price_matte_2_side) + 4;
            }
            else if (row.mdf_thickness == 2) {
                return Number(mdf.price_matte_2_side) + 5.57;
            }
        }
        else if (row.mdf_gloss == 2) {
            if (row.mdf_thickness == 0) {
                return mdf.price_shiny_1_side;
            }
            else if (row.mdf_thickness == 1) {
                return Number(mdf.price_shiny_1_side) + 4;
            }
            else if (row.mdf_thickness == 2) {
                return Number(mdf.price_shiny_1_side) + 5.57;
            }
        }
        else if (row.mdf_gloss == 3) {
            
            if (row.mdf_thickness == 0) {
                return mdf.price_super_shiny_1_side;
            }
            else if (row.mdf_thickness == 1) {
                return Number(mdf.price_super_shiny_1_side) + 4;
            }
            else if (row.mdf_thickness == 2) {
                return Number(mdf.price_super_shiny_1_side) + 5.57;
            }
        }
        else if (row.mdf_gloss == 4) {
            if (row.mdf_thickness == 0) {
                return mdf.price_super_shiny_2_side;
            }
            else if (row.mdf_thickness == 1) {
                return Number(mdf.price_super_shiny_2_side) + 4;
            }
            else if (row.mdf_thickness == 2) {
                return Number(mdf.price_super_shiny_2_side) + 5.57;
            }
        }
    }

    getMDFPanelPrice(mdf: iMDFItem) {
        if (this.fs.furniture.material_mdf_gloss == 0) {
            if (this.fs.furniture.material_mdf_thickness == 0) {
                return mdf.price_matte_2_side;
            }
            else if (this.fs.furniture.material_mdf_thickness == 1) {
                return Number(mdf.price_matte_2_side) + 4;
            }
            else if (this.fs.furniture.material_mdf_thickness == 2) {
                return Number(mdf.price_matte_2_side) + 5.57;
            }
        }
        else if (this.fs.furniture.material_mdf_gloss == 1) {
            if (this.fs.furniture.material_mdf_thickness == 0) {
                return mdf.price_matte_1_side;
            }
            else if (this.fs.furniture.material_mdf_thickness == 1) {
                return Number(mdf.price_matte_1_side) + 4;
            }
            else if (this.fs.furniture.material_mdf_thickness == 2) {
                return Number(mdf.price_matte_1_side) + 5.57;
            }

            
        }
        else if (this.fs.furniture.material_mdf_gloss == 2) {
            if (this.fs.furniture.material_mdf_thickness == 0) {
                return mdf.price_shiny_1_side;
            }
            else if (this.fs.furniture.material_mdf_thickness == 1) {
                return Number(mdf.price_shiny_1_side) + 4;
            }
            else if (this.fs.furniture.material_mdf_thickness == 2) {
                return Number(mdf.price_shiny_1_side) + 5.57;
            }
        }
        else if (this.fs.furniture.material_mdf_gloss == 3) {
            if (this.fs.furniture.material_mdf_thickness == 0) {
                return mdf.price_super_shiny_1_side;
            }
            else if (this.fs.furniture.material_mdf_thickness == 1) {
                return Number(mdf.price_super_shiny_1_side) + 4;
            }
            else if (this.fs.furniture.material_mdf_thickness == 2) {
                return Number(mdf.price_super_shiny_1_side) + 5.57;
            }
        }
        else if (this.fs.furniture.material_mdf_gloss == 4) {
            if (this.fs.furniture.material_mdf_thickness == 0) {
                return mdf.price_super_shiny_2_side;
            }
            else if (this.fs.furniture.material_mdf_thickness == 1) {
                return Number(mdf.price_super_shiny_2_side) + 4;
            }
            else if (this.fs.furniture.material_mdf_thickness == 2) {
                return Number(mdf.price_super_shiny_2_side) + 5.57;
            }
        }
    }


    selectPlinth(type: number) {
        this.fs.furniture.plinth.type = type;

        if (type == 1) { 
            this.fs.furniture.plinth.inner_height = 10;
            this.fs.furniture.plinth.height = 10 + this.fs.edgeWidth;
        }
        else if (type == 2) {
            this.fs.furniture.plinth.legs = this.legs_results[0];
            this.fs.furniture.plinth.height = (Number(this.fs.furniture.plinth.legs.height) / 10) + this.fs.edgeWidth;
            this.fs.furniture.plinth.legs_id = this.fs.furniture.plinth.legs.id;
        }
        else if (type == 3) {
            this.fs.furniture.plinth.height = this.fs.edgeWidth;
        }
        else if (type == 4) {
            this.fs.furniture.plinth.height = 0;
        }

        for(const column of this.fs.furniture.columns) {
            column.height = this.fs.furniture.height - this.fs.furniture.entresolHeight - this.fs.furniture.plinth.height;

            for(const row of column.rows) {
                row.height = column.height / column.rowsCount;
                row.inner_height = (column.height - (this.fs.edgeWidth * column.rowsCount)) / column.rowsCount;
            }
        }
    }

    recalculateColumnsAndRowsHeight() {
        if (!this.fs.furniture.entresol) {
            this.fs.furniture.entresolHeight = 0;
        }
    }

    modifyPlinthHeight(plinthHeight: number) {
        
        if (plinthHeight < 4 || plinthHeight > 20) {
            return;
        }

        this.fs.furniture.plinth.height = plinthHeight + this.fs.edgeWidth;
        this.fs.furniture.plinth.inner_height = plinthHeight;

        this.recalculateColumnsAndRowsHeight();

        for(const column of this.fs.furniture.columns) {
            column.height = this.fs.furniture.height - this.fs.furniture.entresolHeight - this.fs.furniture.plinth.height;

            for(const row of column.rows) {
                row.height = column.height / column.rowsCount;
                row.inner_height = (column.height - this.fs.edgeWidth) / column.rowsCount;
            }
        }

    }

    modifyEntresolHeight(newEntresolHeight: number) {
        this.fs.furniture.entresolHeight = newEntresolHeight;

        this.recalculateColumnsAndRowsHeight();

        for(const column of this.fs.furniture.columns) {
            column.height = this.fs.furniture.height - this.fs.furniture.entresolHeight - this.fs.furniture.plinth.height;

            for(const row of column.rows) {
                row.height = column.height / column.rowsCount;
                row.inner_height = (column.height - this.fs.edgeWidth) / column.rowsCount;
            }
        }
    }



    getOuterName(type: number) {
        if (type == 0) {
            return 'Nėra';
        }
        else if (type == 1) {
            return 'Kairinės';
        }
        else if (type == 2) {
            return 'Dešininės';
        }
        else if (type == 3) {
            return 'Dvigubos';
        }
        else if (type == 4) {
            return 'Viršutinės';
        }
        else if (type == 5) {
            return 'Apatinės';
        }
        else if (type == 6) {
            return 'Stalčius';
        }
    }



    setInner(selection) {
        if (selection == 1) {
            if (this.fs.furniture.activeRow.height < 50 ) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.inner = selection;
            }
        }
        else if (selection == 2) {
            if (this.fs.furniture.activeRow.height < 90 ) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.inner = selection;
            }
        }
        else if (selection == 4) {
            if (this.fs.furniture.activeRow.height < 6 || 
                this.fs.furniture.activeRow.height > 85 || 
                this.fs.furniture.activeRow.width < 15 ||
                this.fs.furniture.activeRow.width > 120 ||
                this.fs.furniture.depth < 20) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.inner = selection;
            }
        }
        else if (selection == 5 || selection == 6 || selection == 7) {
            if (this.fs.furniture.activeRow.height < 6 || 
                this.fs.furniture.activeRow.height > 85 || 
                this.fs.furniture.activeRow.width < 30 ||
                this.fs.furniture.activeRow.width > 240 ||
                this.fs.furniture.depth < 20) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.inner = selection;
            }
        }
        else {
            this.fs.furniture.activeRow.inner = selection;
        }
    }

    innerIsDisabled(selection) {
        if (selection == 1) {
            if (this.fs.furniture.activeRow.height < 50 ) {
                return true;
            }
            else {
                return false
            }
        }
        else if (selection == 2) {
            if (this.fs.furniture.activeRow.height < 90 ) {
                return true;
            }
            else {
                return false
            }
        }
        else if (selection == 4) {
            if (this.fs.furniture.activeRow.height < 6 || 
                this.fs.furniture.activeRow.height > 85 || 
                this.fs.furniture.activeRow.width < 15 || 
                this.fs.furniture.activeRow.width > 120 || 
                this.fs.furniture.depth < 20) {
                return true;
            }
            else {
                return false
            }
        }
        else if (selection == 5 || selection == 6 || selection == 7) {
            if (this.fs.furniture.activeRow.height < 6 || 
                this.fs.furniture.activeRow.height > 85 || 
                this.fs.furniture.activeRow.width < 30 ||
                this.fs.furniture.activeRow.width > 240 ||
                this.fs.furniture.depth < 20) {
                    return true;
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    }


    viewDoorsPanel(item: iPanelItem) {
        this.fs.panelItem = item;
        this.$mdDialog.show({
            template: '<fi-material-dialog></fi-material-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    selectPanelFilling(column: IColumnItem, row: IRowItem) {

        this.fs.furniture.activeColumn = column;
        this.fs.furniture.activeRow = row;

        this.$mdDialog.show({
            template: '<fi-select-panel-filling-dialog></fi-select-panel-filling-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    changeXLeftPosition() {
        this.fs.furniture.wall_alignment_right = this.fs.furniture.wall_width - this.fs.furniture.width - this.fs.furniture.wall_alignment_left;
    }

    changeXRightPosition() {
        this.fs.furniture.wall_alignment_left = this.fs.furniture.wall_width - this.fs.furniture.width - this.fs.furniture.wall_alignment_right;
    }

    changeYBottomPosition() {
        this.fs.furniture.wall_alignment_top = this.fs.furniture.wall_height - this.fs.furniture.height - this.fs.furniture.wall_alignment_bottom;
    }

    changeYTopPosition() {
        this.fs.furniture.wall_alignment_bottom = this.fs.furniture.wall_height - this.fs.furniture.height - this.fs.furniture.wall_alignment_top;
    }

    changeWallWidth() {
        this.changeXLeftPosition();
        this.changeXRightPosition();
    }

    changeWallHeight() {
        this.changeYBottomPosition();
        this.changeYTopPosition();
    }
    
    viewSelectedPanelFilling(item) {
        this.fs.items = this.all;
        this.fs.fillingItem = item;

        this.$mdDialog.show({
            template: '<fi-panel-filling-dialog></fi-panel-filling-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    viewSelectedPanelHandle(item) {
        this.fs.items = this.all;
        this.fs.handleItem = item;
        this.$mdDialog.show({
            template: '<fi-fe-handle-dialog></fi-fe-handle-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    viewSelectedPanelInternalHandle(item) {
        this.fs.items = this.all;
        this.fs.handleInternalItem = item;
        this.$mdDialog.show({
            template: '<fi-fe-handle-internal-dialog></fi-fe-handle-internal-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    selectPanelHandle(column: IColumnItem, row: IRowItem) {

        this.fs.furniture.activeColumn = column;
        this.fs.furniture.activeRow = row;

        this.$mdDialog.show({
            template: '<fi-select-panel-handle-dialog></fi-select-panel-handle-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    selectSlidingDoorHandle(door: ISlidingDoorItem, row: IDivideRows) {
        this.fs.furniture.activeDoor = door;
        this.fs.furniture.activeDoorRow = row;

        this.$mdDialog.show({
            template: '<fi-select-sliding-door-handle-dialog></fi-select-sliding-door-handle-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    viewMilledKnob(item) {
        this.fs.items = this.all;
        this.fs.milledKnobItem = item;
        this.$mdDialog.show({
            template: '<fi-milled-knob-dialog></fi-milled-knob-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }


    selectPlinthLegs() {
        this.$mdDialog.show({
            template: '<fi-select-plinth-dialog></fi-select-plinth-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    selectFurnitureMaterial(side: number) {

        // 1 - Fasadas
        // 2 - Left side
        // 3 - Right side
        // 4 - Top side
        // 5 - Bottom side
        
        this.$mdDialog.show({
            template: '<fi-select-material-dialog side="' + side + '"></fi-select-material-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    selectFurnitureSlidingDoorsPanelMaterial(door: ISlidingDoorItem, row: IDivideRows) {
        this.fs.furniture.activeDoor = door;
        this.fs.furniture.activeDoorRow = row;

        this.$mdDialog.show({
            template: '<fi-select-sliding-doors-panel-material-dialog></fi-select-sliding-doors-panel-material-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    selectFurniturePanelMaterial(column: IColumnItem, row: IRowItem) {

        this.fs.furniture.activeColumn = column;
        this.fs.furniture.activeRow = row;

        this.$mdDialog.show({
            template: '<fi-select-panel-material-dialog></fi-select-panel-material-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    selectMaterialFilling() {
        this.$mdDialog.show({
            template: '<fi-select-material-filling-dialog></fi-select-material-filling-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }


    viewSelectedMaterialLmdp(item) {
        this.fs.panelItem = item;
        this.$mdDialog.show({
            template: '<fi-material-dialog type="1"></fi-material-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    viewSelectedMaterialMdf(item, mdfGloss) {
        this.fs.mdfItem = item;
        this.fs.selected_mdf_gloss = mdfGloss;
        this.$mdDialog.show({
            template: '<fi-material-dialog type="4"></fi-material-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    viewSelectedMaterialPanelLmdp(item) {
        this.fs.panelItem = item;
        this.$mdDialog.show({
            template: '<fi-material-dialog type="2"></fi-material-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    selectFurnitureKnob() {
        this.$mdDialog.show({
            template: '<fi-select-furniture-knob></fi-select-furniture-knob>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    viewKnobProfile(item) {
        this.fs.knobItem = item;
        this.$mdDialog.show({
            template: '<fi-knob-dialog></fi-knob-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    viewSelectedMaterialPanelMdf(item, type, gloss, thickness, price) {
        this.fs.mdfItem = item;
        this.$mdDialog.show({
            template: '<fi-material-dialog type="' + type + '" gloss="' + gloss + '" thickness="' + thickness + '" price="' + price + '"></fi-material-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    viewSelectedMirror(item) {
        this.fs.items = this.all;
        this.fs.mirrorItem = item;
        this.$mdDialog.show({
            template: '<fi-mirror-dialog></fi-mirror-dialog>',      
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    viewSelectedPlinthLegs(item) {
        this.fs.items = this.all;
        this.fs.legItem = item;
        this.$mdDialog.show({
            template: '<fi-fe-leg-dialog></fi-fe-leg-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    selectHandleType(selection) {

        // if (selection == 0) {
            //this.fs.furniture.activeRow.handleType = 0;
            //this.fs.furniture.activeRow.handle = null;
            //this.fs.furniture.activeRow.handle_id = 0;

        //}
        //else if (selection == 1) {
            //this.fs.furniture.activeRow.handleType = 1;
            //this.fs.furniture.activeRow.handle = this.handles_results[0];
        //}
        //else {
            //this.fs.furniture.activeRow.handleType = selection;
        //}


        if (this.fs.sameHandles) {
            this.fs.furniture.activeRow.handleType = selection;
            for(const column of this.fs.furniture.columns) {
                for(const row of column.rows) {
                    row.handleType = selection;

                    if (selection == 0 || selection == 3) {
                        row.handle = null;
                        row.handle_id = 0;
                    }
                }
            }
        }
        else {
            this.fs.furniture.activeRow.handleType = selection;
            if (selection == 0 || selection == 3) {
                this.fs.furniture.activeRow.handle = null;
                this.fs.furniture.activeRow.handle_id = 0;
            }
        }
        
    }

    setOuter(selection) {

        if (!this.fs.furniture.activeRow.panel) {
            this.fs.furniture.activeRow.panel = this.fs.furniture.material;
            this.fs.furniture.activeRow.panel_id = this.fs.furniture.material_id;
        }

        if (selection == 6) {
            if (this.fs.furniture.activeRow.height > 10 && this.fs.furniture.activeRow.height > 100) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.outer = selection;
            }
        }
        else if (selection == 0) {
            this.fs.furniture.activeRow.outer = 0;
            this.fs.furniture.activeRow.panel = null;
            this.fs.furniture.activeRow.panel_id = null;
        }
        else {
            this.fs.furniture.activeRow.outer = selection;
            
        }
    }

    outerIsDisabled(type: number) {

        if (type == 1) {
            if (
                this.fs.furniture.activeRow.height > 10 && 
                this.fs.furniture.activeRow.height < 275 &&
                this.fs.furniture.activeRow.width > 8 &&
                this.fs.furniture.activeRow.width < 65) {
                return true;
            }
            else {
                console.log('faaaa');
                return false;
                
            }
        }
        else if (type == 2) {

        }
        else if (type == 3) {

        }
        else if (type == 4) {

        }
        else if (type == 5) {

        }
        else if (type == 6) {
            if (
                this.fs.furniture.activeRow.height > 8 && 
                this.fs.furniture.activeRow.height < 85 &&
                this.fs.furniture.activeRow.width > 15 &&
                this.fs.furniture.activeRow.width < 120 &&
                this.fs.furniture.depth > 20) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    modifyCart(itemCount: number) {
        if (itemCount < 1 || itemCount > 99) {
            return;
        }

        this.itemCartCount = itemCount;
    }


    openRequest() {

        this.$mdDialog.show({
            template: '<fi-request-dialog></fi-panel-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });

    }
    

    contactMe() {
        this.$mdDialog.show({
            template: '<fi-contact-me></fi-contact-me>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    addToCart() {
        
        this.cartStorage = JSON.parse(localStorage.getItem('cart'));
        this.fs.furniture.price = this.ps.getPrice(this.fs.furniture);

        if (this.cartStorage) {
            this.cart.items = this.cartStorage.items;
        }
        this.cart.items.push({
            item: this.fs.furniture,
            count: this.itemCartCount,
            assemblyPrice: this.ps.getAssemblyPrice(),
            carryPrice: this.ps.getCarryPrice()
        });

        // console.log(this.cart.items);

        this.cart.count = this.cart.items.length;
        this.cart.environment = this.fs.environment;

        localStorage.setItem('cart', JSON.stringify(this.cart));

        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ ecommerce: null });  // Clear the previous ecommerce object.
        window['dataLayer'].push({
        'event': 'addToCart',
        'ecommerce': {
            'currencyCode': 'EUR',
            'add': {                                // 'add' actionFieldObject measures.
            'products': [{                        //  adding a product to a shopping cart.
                'name': this.fs.furniture.title,
                'id': this.fs.furniture.item_id,
                'price': this.fs.furniture.price,
                'quantity': this.itemCartCount
            }]
            }
        }
        });

        this.$window.location.href = '/krepselis';

        // this.showCartLink = true;
        
    }

}
