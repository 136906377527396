import './FiItemPointDialog.scss';
import { FiFurnitureService, iPoint } from '../../components/fiFurniture/FiFurnitureService';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiItemPointDialog.html'),
    bindings: {

    },
})
export class FiItemPointDialog {
    static $inject = [
        '$mdDialog',
        '$http',
        'fiFurnitureService',
    ];


    constructor(
        private $mdDialog: angular.material.IDialogService,
        private $http: angular.IHttpService,
        private fs: FiFurnitureService,
    ) {
        //
    }

    images = [];

    $onInit() {
        this.$http.post("https://www.fitit.lt/db/get-point-images.php", { 
            point_id: this.fs.point.point_id
        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {
                this.images.push(response.data);
        });
    }

    close() {
        this.$mdDialog.cancel();
    }

    delete(point: iPoint) {
        this.$http.post("https://www.fitit.lt/db/delete-point.php", { 
            point_id: this.fs.point.point_id
        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                //
        });

        this.fs.furniture.points.splice(this.fs.furniture.points.indexOf(point), 1);
        this.$mdDialog.cancel();
    }

    deleteImage(pointId: string, imageName: string) {

        // this.loading = true;

        this.$http.post("https://www.fitit.lt/db/delete-point-photo.php", { 

            pointId: pointId,
            imageName: imageName

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                // console.log(response);
        });


        //this.$timeout(() => {

          //  location.reload();

        //}, 2000);

    }

    save() {

        if (!this.fs.point.point_id) {
            this.fs.point.point_id = 'P_' + Math.floor(Math.random() * 89999999 + 10000000);
        }

        this.$http.post("https://www.fitit.lt/db/edit-point.php", { 

            item_id: this.fs.furniture.item_id,
            point_id: this.fs.point.point_id,
            title: this.fs.point.title,
            description: this.fs.point.description,
            // photo_1: this.photo_1,
            x_axis: this.fs.point.x_axis,
            y_axis: this.fs.point.y_axis,
            width: this.fs.point.width,
            height: this.fs.point.height,

        }, { headers: {'Content-Type': 'application/json'} })
        .then(function (response) {
            //
        });

        this.fs.furniture.points.push(this.fs.point);

        this.$mdDialog.cancel();
    }

}